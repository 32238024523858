<template lang="html">
  <div class="WatUX">
    <iframe
      id="wat-ux-iframe"
      :src="url"
      height="100%"
      width="100%"
      frameborder="0">
    </iframe>

    <!-- Notification for required permissions to fix Firefox required interaction -->
    <Modal :title="$t('WatUX.permissions.required')" :show="showPermisionsWarnModal">
      <template v-slot:content>
        <ul>
          <li v-if="uxData.recordScreen">{{ $t('WatUX.screen') }}</li>
          <li v-if="uxData.recordMicrophone">{{ $t('WatUX.microphone') }}</li>
          <li v-if="uxData.recordWebcam">{{ $t('WatUX.webcam') }}</li>
        </ul>
      </template>
      <template v-slot:footer>
        <button type="button" name="dismissPermissionButton" @click="initiateRecord()">{{ $t('WatUX.understood') }}</button>
      </template>
    </Modal>

    <!-- Notify missing permissions -->
    <Modal :title="$t('WatUX.permissions.errors.nopermissions_title')" :show="showMissingPermissionsModal">
      <template v-slot:content>
        <ul>
          <li v-if="uxData.recordScreen">{{ $t('WatUX.permissions.errors.screen') }}</li>
          <li v-if="uxData.recordMicrophone">{{ $t('WatUX.permissions.errors.microphone') }}</li>
          <li v-if="uxData.recordWebcam">{{ $t('WatUX.permissions.errors.camera') }}</li>
        </ul>
      </template>
      <template v-slot:footer>
        <button type="button" name="dismissPermissionButton" @click="dismissPermissionModal">{{ $t('WatUX.refresh_mode') }}</button>
      </template>
    </Modal>

    <!-- Display pending checkpoints -->
    <Modal :title="$t('WatUX.checkpoints.pending_title')" :show="showPendingCheckpointsModal">
      <template v-slot:content>
        <ul>
          <li v-for="checkpoint in checkpoints" :key="checkpoint.checkId">
            <span class="Checkpoint__Icon" :class="checkpoint.completed ? 'Checkpoint__Icon--completed' : 'Checkpoint__Icon--incomplete'">
              <svg xmlns="http://www.w3.org/2000/svg" v-if="!checkpoint.completed" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" width="24" height="24">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" v-else viewBox="0 0 20 20" fill="currentColor" width="24" height="24">
                <path fill-rule="evenodd" d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
              </svg>
            </span>
            {{ checkpoint.message }}
          </li>
        </ul>
      </template>
      <template v-slot:footer>
        <button type="button" name="dismissButton" @click="dismissModal">{{ $t('WatUX.understood') }}</button>
      </template>
    </Modal>

    <!-- Display data submission in progress -->
    <Modal :title="$t('WatUX.submit.title')" :show="showDataSubmissionModal">
      <template v-slot:content>
        <ul>
          <li v-if="this.uxData.recordScreen" class="WatUX--UploadProgress">
            <span v-if="!screenRecordSent && !screenRecordError">{{ $t('WatUX.submit.store.screen.process') }}</span>
            <span v-if="screenRecordError">{{ $t('WatUX.submit.errors.store.screen') }}</span>
            <span v-else-if="screenRecordSent">{{ $t('WatUX.submit.store.screen.success') }}</span>
            <div class="WatUX--UploadProgressBar">
              <div class="WatUX--UploadProgressCount" :style="{'width':screenUploadProgress+'%'}"></div>
            </div>
            <span class="WatUX--UploadProgressNum">{{screenUploadProgress}}%</span>
          </li>
          <li v-if="this.uxData.recordWebcam" class="WatUX--UploadProgress">
            <span v-if="!webcamRecordSent && !webcamRecordError">{{ $t('WatUX.submit.store.camera.process') }}</span>
            <span v-if="webcamRecordError">{{ $t('WatUX.submit.errors.store.camera') }}</span>
            <span v-else-if="webcamRecordSent">{{ $t('WatUX.submit.store.camera.success') }}</span>
            <div class="WatUX--UploadProgressBar">
              <div class="WatUX--UploadProgressCount" :style="{'width':webcamUploadProgress+'%'}"></div>
            </div>
            <span class="WatUX--UploadProgressNum">{{webcamUploadProgress}}%</span>
          </li>
        </ul>
      </template>
    </Modal>

    <!-- Notify errors on data submission -->
    <Modal :title="$t('WatUX.checkpoints.pending_title')" :show="showPendingDataModal">
      <template v-slot:content>
        <div v-if="pendingData['error']">
          {{ $t('WatUX.submit.errors.generic') }}
        </div>
        <ul>
          <li v-if="!pendingData['screenVideoReady']">{{ $t('WatUX.submit.errors.store.screen') }}</li>
          <li v-if="!pendingData['webcamVideoReady']">{{ $t('WatUX.submit.errors.store.camera') }}</li>
          <li v-for="checkpoint in pendingData['pendingCheckpoints']" :key="checkpoint.checkId">
            <span v-if="checkpoint.completed">✓</span>
            <span v-if="!checkpoint.completed">✗</span>
            {{ checkpoint.message }}
          </li>
        </ul>
      </template>
      <template v-slot:footer>
        <button type="button" name="dismissPermissionButton" @click="dismissPermissionModal">{{ $t('WatUX.refresh_mode') }}</button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getStoredRecords, launchScreenRecord, stopScreenRecord } from '~/utils/ux-recorder';
import store from '@/store'
import Modal from '../Modal/Modal.vue'
export default {
  name: 'WatUX',

  props: {
    url:{
      type: String,
      default: ""
    },
    activityId:{
      type: Number,
      default: 1,
    },
    communityId:{
      type: Number,
      default: 1,
    },
    questionId:{
      type: Number,
      default: 1,
    },
    uxData:{
      type: Object,
      default: {},
    },
  },

  components: {
    Modal
  },

  data(){
    return{
      webTestIframeMinTime        : 20,
      checkpoints                 : [],
      checkpointUrl               : '',
      dataValidationUrl           : '',
      videoStorageUrl             : '',
      completedCheckpoints        : {},
      pendingData                 : [],
      showPendingDataModal        : false,
      showPermisionsWarnModal     : false,
      showDataSubmissionModal     : false,
      showPendingCheckpointsModal : false,
      showMissingPermissionsModal : false,
      screenRecordSent            : false,
      webcamRecordSent            : false,
      screenRecordError           : false,
      webcamRecordError           : false,
      interval                    : '',
      screenRecordUrl             : '',
      webcamRecordUrl             : '',
      testFinished                : false,
      webcamUploadProgress        : 0,
      screenUploadProgress        : 0,
      recordAllowed               : false  
    }
  },

  computed: {
    ...mapGetters([
      'origin',
    ]),

    pendingCheckpoints: function() {
      for (let checkpoint of this.checkpoints) {
        if (checkpoint.completed == false) {
          return true;
        }
      }

      return false;
    },
  },
  watch: {
    pendingCheckpoints(status) {
      this.$emit('notifyCheckpointsFinished', !status);
    },
    '$route' (to, from) {
      if (from.name == 'activities.watux' && this.recordAllowed && !this.testFinished) {
        stopScreenRecord();
      }
    }
  },
  methods: {
    sendToComments: function() {
      if (this.pendingCheckpoints) {
        this.showPendingCheckpointsModal = true;
        return;
      }

      stopScreenRecord();
      this.$emit('hide-sidebar')
      this.$emit('notifyRecording', false, false);

      this.storeRecords();
    },

    storeRecords: function() {
      this.showDataSubmissionModal = true;

      var storedRecords = getStoredRecords();

      if (!storedRecords) {
        if (this.interval == '') {
          this.interval = setInterval(() => {
            this.storeRecords()
          }, 1000);
        }
        return;
      }

      clearInterval(this.interval);

      if (storedRecords.screenFile != '' && !this.screenRecordSent) {
        var data = {
          testId: this.activityId,
          file: storedRecords.screenFile,
          onUploadProgress: progressEvent => {
            this.screenUploadProgress = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
          },
        };

        store.dispatch('STORE_TEST_RECORDINGS', data)
        .then((response) => {
          this.screenRecordSent = true;
          this.screenRecordUrl = response.path;
        })
        .catch((error) => {
          this.screenRecordSent = true;
          this.screenRecordError = true;
          console.log(error);
        });
      }

      if (storedRecords.cameraFile != '' && !this.webcamRecordSent) {
        var data = {
          testId: this.activityId,
          file: storedRecords.cameraFile,
          onUploadProgress: progressEvent => {
            this.webcamUploadProgress = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
          },
        };

        store.dispatch('STORE_TEST_RECORDINGS', data)
        .then((response) => {
          this.webcamRecordSent = true;
          this.webcamRecordUrl = response.path;
        })
        .catch((error) => {
          this.webcamRecordSent = true;
          this.webcamRecordError = true;
          console.log(error);
        });
      }

      this.interval = '';
      this.notifyRecordInsertion();
    },

    notifyRecordInsertion: function() {
      if (!this.webcamRecordSent || !this.screenRecordSent) {
        if (this.interval == '') {
          this.interval = setInterval(() => {
            this.notifyRecordInsertion()
          }, 1000);
        }
        return;
      }

      clearInterval(this.interval);

      var data = {
        url: this.videoStorageUrl,
        testId: this.activityId,
        screenVideoUrl: this.screenRecordUrl,
        webcamVideoUrl: this.webcamRecordUrl,
      };

      store.dispatch('NOTIFY_COMPLETION', data)
      .then((response) => {
        this.validateData();
      })
      .catch((error) => {
        console.log(error);
      });
    },

    validateData: function() {
      this.showDataSubmissionModal = false;

      store.dispatch('VALIDATE_DATA_COMPLETION', {testId: this.activityId, url: this.dataValidationUrl})
      .then((response) => {
        if (response.pendingCheckpoints.length < 1 && response.screenVideoReady && response.webcamVideoReady) {
          this.testFinished = true;
          this.$emit('hide-sidebar', false)
          this.$router.push({name: 'activity.info', params: { origin: this.origin, id: this.activityId }});
        } else {
          this.pendingData['screenVideoReady']   = response.screenVideoReady;
          this.pendingData['webcamVideoReady']   = response.webcamVideoReady;
          this.pendingData['pendingCheckpoints'] = response.pendingCheckpoints;

          this.showPendingDataModal = true;
        }
      })
      .catch((error) => {
        console.log(error);
        this.pendingData['error'] = true;
        this.showPendingDataModal = true;
      });
    },

    iframeTimer: async function(){
      if (this.webTestIframeMinTime > 0) {
        this.webTestIframeMinTime= this.webTestIframeMinTime -1
        setTimeout(() => this.iframeTimer(), 1000);
      }
    },

    handleMessage: function(event) {
      switch (event.data.type) {
        case 'pageLoaded':
          this.checkPageLoadcheckpoints(event.data);
          this.registerEventListeners();
          break;
        case 'checkpointCompleted':
          this.markCheckpointAsCompleted(event.data);
          break;
        default:
          // no event available
          break;
      }
    },

    registerEventListeners: function() {
      let checkpointCount = this.checkpoints.length;

      for (let i = 0; i < checkpointCount; i++) {
        document.getElementById('wat-ux-iframe').contentWindow.postMessage(JSON.parse(JSON.stringify(this.checkpoints[i])), '*');
      }
    },

    checkPageLoadcheckpoints: function(data) {
      const navigationEvents = [
        'url_load',
      ];

      this.checkpoints.filter(checkpoint => {
        if (navigationEvents.includes(checkpoint.type.toLowerCase()) && checkpoint.checkUrl == data.url) {
          checkpoint.completed = true;

          if (!Object.keys(this.completedCheckpoints).includes(checkpoint.checkId)) {
            this.completedCheckpoints[checkpoint.checkId] = {
              checkId : checkpoint.checkId,
              completedDate: new Date().toISOString(),
            };

            this.notifyCheckpointCompletion();
          }

          return true;
        }

        return false;
      });
    },

    markCheckpointAsCompleted: function(data) {
      this.checkpoints.filter(checkpoint => {
        if (checkpoint.checkId == data.checkId) {
          checkpoint.completed = data.completed;

          if (!Object.keys(this.completedCheckpoints).includes(checkpoint.checkId)) {
            this.completedCheckpoints[checkpoint.checkId] = {
              checkId : checkpoint.checkId,
              completedDate: new Date().toISOString(),
            };

            this.notifyCheckpointCompletion();
          }

          return true;
        }

        return false;
      });
    },

    notifyCheckpointCompletion: function() {
      var data = {
        url: this.checkpointUrl,
        testId: this.activityId,
        checkpointsCompleted: [],
      };

      for (let checkpoint in this.completedCheckpoints) {
        data.checkpointsCompleted.push(this.completedCheckpoints[checkpoint]);
      }

      store.dispatch('NOTIFY_COMPLETION', data)
      .catch((error) => {
        console.log(error);
      });

      this.$emit('notifyCheckpointCompletion', this.checkpoints);
    },

    assignCheckpoints: function() {
      let checkpointCount = this.uxData.checkpoints.length;

      for (let i = 0; i < checkpointCount; i++) {
        this.uxData.checkpoints[i].completed = false;
      }

      this.checkpoints = this.uxData.checkpoints;
    },

    assignUrl: function() {
      this.checkpointUrl     = '/tester/communities/' + this.communityId + '/activity/' + this.activityId + '/question/' + this.questionId + '/uxCheckpointCompleted';
      this.dataValidationUrl = '/tester/communities/' + this.communityId + '/activity/' + this.activityId + '/question/' + this.questionId + '/uxDataValidation';
      this.videoStorageUrl   = '/tester/communities/' + this.communityId + '/activity/' + this.activityId + '/question/' + this.questionId + '/uxVideos';
    },

    warnForPermissions: async function() {
      this.showPermisionsWarnModal = true;
    },

    initiateRecord: async function() {
      this.showPermisionsWarnModal = false;

      this.recordAllowed = await launchScreenRecord(this.uxData.recordScreen, this.uxData.recordMicrophone, this.uxData.recordWebcam);

      if (!this.recordAllowed) {
        this.showMissingPermissionsModal = true;
      }

      this.screenRecordSent = !this.uxData.recordScreen;
      this.webcamRecordSent = !this.uxData.recordWebcam;

      this.$emit('notifyRecording', this.uxData.recordScreen, this.uxData.recordWebcam);
    },

    dismissModal: function() {
      this.showPendingCheckpointsModal = false;
    },

    dismissPermissionModal: function() {
      this.$router.go(-1)
    },

    loadMockData: function() {
      this.checkpoints = [
        {
          checkId: 't4374t1c1',
          name:   'Pinchar en el WATMonitor',
          elementSelector: '#menu-item-12633',
          type: 'CLICK',
          completed: false,
          message: 'Pinchar en el WATMonitor del footer',
        },
      ];
    },
  },

  async created() {
    window.addEventListener('message', this.handleMessage);

    if (Object.keys(this.uxData).includes('checkpoints')) {
      this.assignCheckpoints();
    } else {
      this.$emit('notifyCheckpointsFinished', true);
    }
    this.assignUrl();
  },
  async destroyed() {
    window.removeEventListener('message', this.handleMessage);
  },
  async mounted() {
    // this.iframeTimer();
    // this.initiateRecord();
    this.warnForPermissions();
  },
}
</script>


<style lang="scss">
:root {
    --text-color: #ffffff;
    --main-color: #ff1565;
    --button-color: #94ca00;
    --contrast-color: #744ab7;
    --secondary-color:#fd7328;
}
.WatUX{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  iframe{
    padding: 5px;
    border-radius: 6px;
    border: 1px solid #c3c3c3;
    width: 100%;
    background-color: white;
  }
  button{
    background: var(--button-color);
    font-weight: 600;
    font-size: 16px;
    border-radius: 3px;
    border: none;
    padding: .8rem 2rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    color:white;
    margin: 10px 0;
    transition: background .2s ease-in-out;
    max-width: 100%;
    &:hover {
      background: var(--contrast-color);
    }
    &:disabled{
      opacity: 0.5;
    }
  }
  &--UploadProgress{
    position: relative;
  }
  &--UploadProgressBar{
    position: absolute;
    width: 100%;
    height: 3px;
    left: 0;
    bottom: 0;
    background: lightgray;
    border-radius: 3px;
  }
  &--UploadProgressCount{
    height: 3px;
    transition:0.5s;
    background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
  }
  &--UploadProgressNum{
    position: absolute;
    right: 9px;
    font-weight: 700;
  }
}
.Checkpoint {
  &__Icon {
    margin-right: .5rem;
    &--incomplete {
      color: rgba(#000,0.3);
    }
    &--completed {
      color: var(--button-color)
    }
  }
}
</style>
